const binding = { modules: {}, dataActions: {} };

    (binding.modules['moeochsitetheme'] = {
        c: () => require('partner/themes/moeochsitetheme/moeochsitetheme.tsx'),
        $type: 'themeModule',
        da: [],
        definitionExtensions: ['cookie-compliance','footer-item','header','navigation-menu','product-collection','search'],
        iNM: false,
        ns: '__local__',
        n: 'moeochsitetheme',
        p: '__local__',
        
        pdp: '',
        
        themeSettings: 'moeochsitetheme.theme.settings.json',
        md: 'src/themes/moeochsitetheme'
    });
        

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };